import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form } from "antd";
import { useTicketContext } from "lib/context/ticketpurchase/ticketContext";
import { useInitiateTicket } from "lib/hooks/ticketpurchase";
import { InitiateTicketPayload } from "lib/hooks/ticketpurchase";
import { useNavigate, useParams } from "react-router-dom";
import { useTicketPurchaseComplete } from "lib/hooks/ticketpurchase";
import { usePaystackPayment } from "react-paystack";

const Container = styled.div`
  max-width: 100%;
  padding-right: 0.5rem;
`;
const InitializePayment = styled.div``;

const PaymentVerifying = styled.div``;

const PaymentSuccess = styled.div``;

const PaymentFailed = styled.div``;

const Price = styled.div``;

const Pay = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Content = styled.div``;

interface PaystackReference {
  reference: string;
  transaction: string;
  status: string;
}

const Checkout: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const { ticketData, currentView, updateCurrentView } = useTicketContext();
  const { mutate: initiateTicket } = useInitiateTicket();
  const params = useParams<{ id?: string }>();
  const [eventId, setEventId] = useState<number>(0);
  const [shouldRetry, setShouldRetry] = useState(true);
  const { mutate: ticketPurchaseComplete } = useTicketPurchaseComplete();
  const [sessionid, setSessionId] = useState("");
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);

  const onFieldsChange = () => {
    const isValid = form.getFieldsError().every(field => field.errors.length === 0);
    setIsFormValid(isValid);
  };

  const handlePayClick = () => {
    const payload: InitiateTicketPayload = {
      eventId: eventId,
      attendee: ticketData.attendee || { firstName: "", lastName: "", email: "", phone: "" },
      orders: ticketData.orders.map(order => ({
        eventTicketId: order.eventTicketId,
        count: order.count,
      })),
    };

    initiateTicket(payload, {
      onSuccess: data => {
        console.log("Total Price:", data.totalPrice);

        if (data.totalPrice === 0) {
          const completePayload = {
            sessionId: data.sessionId,
            transactionId: data.reference,
          };

          ticketPurchaseComplete(completePayload, {
            onSuccess: response => {
              console.log("Purchase complete:", response);
              updateCurrentView("success");
            },
            onError: error => {
              console.error("Error completing purchase:", error);
            },
          });
        } else {
          const config = {
            reference: new Date().getTime().toString(),
            email: data.attendee.email,
            amount: data.totalPrice * 100, // Convert to kobo
            publicKey: "pk_test_3cb388849dc7837b527ccddb23a81854ffc224f4",
          };

          const initializePayment = usePaystackPayment(config);

          const paystackSuccess = (reference: PaystackReference) => {
            console.log("Payment successful:", reference);
            navigate(`/getticket/checkout/${eventId}`);
            updateCurrentView("verify");
            setSessionId(data.sessionId);
          };

          const paystackClose = () => {
            console.log("Payment dialog closed");
          };

          // Correct usage of initializePayment
          initializePayment({
            onSuccess: paystackSuccess,
            onClose: paystackClose,
          });
        }
      },
      onError: error => {
        console.error("Error during ticket initiation:", error);
      },
    });
  };

  const renderContent = () => {
    switch (currentView) {
      case "price":
        return (
          <Price>
            <Form layout="vertical" form={form} name="register">
              <Form.Item>
                <Pay onClick={handlePayClick}>Pay NGN {ticketData.totalAmount}</Pay>
              </Form.Item>
            </Form>
          </Price>
        );
      case "verify":
        return (
          <PaymentVerifying>
            Verifying ticket purchase... Time left: {Math.floor(timeLeft / 60)}:
            {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
            please wait might take up to 5 mins to confirm
          </PaymentVerifying>
        );
      case "success":
        return <PaymentSuccess>Ticket purchase successful!</PaymentSuccess>;
      case "failed":
        return <PaymentFailed>Ticket purchase failed.</PaymentFailed>;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (currentView === "verify") {
      let retryCount = 0;
      const maxRetries = 15;
      let isRetrying = false;

      const countdownInterval = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      const retryFunction = async () => {
        if (retryCount >= maxRetries || isRetrying) {
          if (retryCount >= maxRetries) {
            clearInterval(countdownInterval);
            clearInterval(retryInterval);
            updateCurrentView("failed");
          }
          return;
        }

        isRetrying = true; // Lock retry function to prevent overlap
        ticketPurchaseComplete(
          { sessionId: sessionid },
          {
            onSuccess: () => {
              clearInterval(countdownInterval);
              clearInterval(retryInterval);
              updateCurrentView("success");
            },
            onError: error => {
              console.error(`Retry attempt ${retryCount + 1} failed:`, error);
              retryCount++;
              isRetrying = false; // Unlock retry function after failure
            },
          },
        );
      };

      const retryInterval = setInterval(retryFunction, 20000);

      retryFunction(); // Immediate first attempt

      return () => {
        clearInterval(countdownInterval);
        clearInterval(retryInterval);
      };
    }
  }, [currentView, sessionid, ticketPurchaseComplete, updateCurrentView]);

  return (
    <Container>
      <Content>{renderContent()}</Content>
    </Container>
  );
};

export default Checkout;
