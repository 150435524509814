import { useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

interface CheckInPayload {
  ticketCode: string;
  firstName?: string;
  sessionId: string;
}

export function useTicketCheckIn() {
  return useMutation({
    mutationKey: [tqMutationKeys.ticket.checkIn],
    mutationFn: async (payload: CheckInPayload) => {
      const { ticketCode, firstName, sessionId } = payload;

      const params = {
        ...(firstName && { firstName }),
        ...(sessionId && { sessionId }),
        ticketCode, // Required parameter
      };

      const res = await EvendyApi.patch<string>("ticket-purchase/check-in", {}, { params });
      return res.data;
    },
  });
}
