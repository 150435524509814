import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { EvendyApi } from "lib/utils/api-utils";
import { tqQueryKeys } from "lib/constants";

interface Attendee {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
}

export interface TicketPurchase {
  sessionId: string;
  ticketType: string;
  ticketCode: string;
  validated: boolean;
  attendee: Attendee;
}

interface TicketPurchaseResponse {
  data: TicketPurchase[];
  page: number;
  limit: number;
  totalCount: number;
}

type TicketPurchaseResult = UseQueryResult<TicketPurchaseResponse, Error>;

interface TicketPurchaseParams {
  eventId: number;
  ticketCode?: string;
  page: number;
  limit: number;
}

export function useGetTicketPurchaseByEventId({
  eventId,
  ticketCode,
  page,
  limit,
}: TicketPurchaseParams): TicketPurchaseResult {
  return useQuery({
    queryKey: [tqQueryKeys.ticket.getTicketByEventId, eventId, ticketCode, page, limit],
    queryFn: async (): Promise<TicketPurchaseResponse> => {
      const params = new URLSearchParams({
        page: page.toString(),
        limit: limit.toString(),
      });

      if (ticketCode) {
        params.append("ticketCode", ticketCode);
      }

      const res = await EvendyApi.get<TicketPurchaseResponse>(
        `ticket-purchase/find-by-eventId/${eventId}?${params.toString()}`,
      );

      console.log("API Response:", res.data);
      return res.data;
    },
    enabled: !!eventId,
    retry: false,
  });
}
