import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Pagination } from "antd";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { useGetTicketPurchaseByEventId } from "lib/hooks/ticketing";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";

const HomeContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #f9fafb;
  width: 480px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const BackToHome = styled.div``;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`;

const Top = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Search = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const EventList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const TicketCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  justify-content: space-between;
  border: 1px solid #cecece;
  border-radius: 8px;
  background-color: #ffffff;
`;

const AttendeesEvent = () => {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const eventId = params.id ? Number(params.id) : null;

  // State variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [scannerActive, setScannerActive] = useState(false);
  const [scannedData, setScannedData] = useState("");

  // Debounce search input (3 seconds delay)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 3000);

    return () => clearTimeout(handler);
  }, [searchQuery]);

  // Fetch tickets with debounced search input
  const {
    data: eventTickets,
    isLoading,
    isError,
  } = useGetTicketPurchaseByEventId({
    eventId: eventId || 0,
    ticketCode: debouncedSearch,
    page: currentPage,
    limit: pageSize,
  });

  // Handle pagination change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Handle scanning result
  const handleScan = (_: unknown, result?: { getText: () => string }) => {
    if (result) {
      const scannedText = result.getText();
      const parts = scannedText.split("#");
      const sessionId = parts[0];
      const ticketNumber = parts[1];

      if (ticketNumber) {
        setScannedData(ticketNumber);
        setScannerActive(false);
        navigate(`${routeNames.attendeesTicketDetails}/${sessionId}/${ticketNumber}`);
      }
    }
  };

  return (
    <HomeContainer>
      <Top>
        <StyledLink to={routeNames.attendeesHome}>
          <BackToHome>
            <LeftOutlined /> Back to Events
          </BackToHome>
        </StyledLink>
      </Top>
      <Search>
        <SearchBar onSearch={setSearchQuery} />
      </Search>
      <EventList>
        {eventTickets?.data.map((ticket: any) => (
          <TicketCard
            key={ticket.id}
            onClick={() => navigate(`${routeNames.attendeesTicketDetails}/${ticket.sessionId}/${ticket.ticketCode}`)}
          >
            <div>
              <p>Ticket Code: {ticket.ticketCode}</p>
              <p>Ticket Type: {ticket.ticketType}</p>
            </div>
            <p style={{ color: "green" }}>{ticket?.validated ? "Checked-in" : "Not checked-in"}</p>
          </TicketCard>
        ))}
      </EventList>
      <Button type="primary" onClick={() => setScannerActive(true)}>
        Scan Ticket QR Code
      </Button>
      {scannerActive && <BarcodeScannerComponent width={500} height={500} onUpdate={handleScan} />}

      {!isLoading && !isError && (
        <PaginationContainer>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={eventTickets?.totalCount || 0}
            onChange={handlePageChange}
            size="small"
          />
        </PaginationContainer>
      )}
    </HomeContainer>
  );
};

export default AttendeesEvent;
