import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BottomContainer } from "../website/landing/faq/faq.style";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTicketByCode } from "lib/hooks/ticketing";
import { useTicketCheckIn } from "lib/hooks/ticketing";
import { message } from "antd";

const DetailsContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 480px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LargeText = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin: 0;
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #475467;
  margin: 0;
`;

const SmallGrey = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #9c9c9c;

  margin: 0;
`;

const SmallGreen = styled.p`
  margin: 0;
`;

const MediumBlack = styled.p`
  margin: 0;
`;

const GreenText = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #17b26a;
`;

const Cancel = styled.button`
  background-color: #ffffff00;
  color: #595959;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  padding: 10px 10px;
  font-size: 13px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* margin-top: 0.5rem; */
  margin-bottom: 1rem;
  cursor: pointer;
`;

const CheckIn = styled.button<{ validated?: boolean }>`
  background-color: ${({ validated }) => (validated ? "#d3d3d3" : "#1390e6")};
  color: ${({ validated }) => (validated ? "#17B26A" : "white")};
  /* border: 1px solid; */
  border: none;
  /* border-color: ${({ validated }) => (validated ? "#b0b0b0" : "#1390e6")}; */
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: ${({ validated }) => (validated ? "not-allowed" : "pointer")};
`;

const AttendeesTicketDetails = () => {
  const navigate = useNavigate();
  const { sessionId, id } = useParams<{ sessionId?: string; id?: string }>(); // Extract directly
  const [ticketcode, setTicketCode] = useState(id || "");
  const [sessionid, setSessionId] = useState(sessionId || "");

  useEffect(() => {
    if (id) {
      setTicketCode(id);
    }

    if (sessionId) {
      setSessionId(sessionId);
    }
  }, []); // Add sessionId to dependency array

  // Fetch ticket by code
  const {
    data: ticket,
    isLoading,
    isError,
  } = useGetTicketByCode({
    ticketCode: ticketcode,
    sessionId: sessionid,
  });

  const checkInMutation = useTicketCheckIn();

  const handleCheckIn = () => {
    checkInMutation.mutate(
      { ticketCode: ticketcode, sessionId: sessionid },
      {
        onSuccess: () => {
          message.success("Check-in successful");
          navigate(-1); // Navigates back to the previous page
        },
        onError: () => {
          message.error("Check-in failed. Please try again.");
        },
      },
    );
  };

  const handleCancel = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <DetailsContainer>
      <Top>
        <LargeText>Ticket Details</LargeText>
        <SmallText>Select an event to check-in attendees.</SmallText>
      </Top>
      <Bottom>
        <Detail>
          <SmallGrey>Ticket status:</SmallGrey>
          <GreenText>{ticket?.validated ? "Checked-in" : "Not checked-in"}</GreenText>
        </Detail>
        <Detail>
          <SmallGrey>Event name:</SmallGrey>
          <MediumBlack>{ticket?.eventName}</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Ticket type:</SmallGrey>
          <MediumBlack>{ticket?.ticketType}</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Ticket code:</SmallGrey>
          <MediumBlack>{ticket?.ticketCode}</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Purchased by:</SmallGrey>
          <MediumBlack>{ticket?.attendee?.firstName}</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Purchaser email:</SmallGrey>
          <MediumBlack>{ticket?.attendee?.email}</MediumBlack>
        </Detail>
        <Detail>
          <SmallGrey>Purchaser phone number:</SmallGrey>
          <MediumBlack>{ticket?.attendee?.phone}</MediumBlack>
        </Detail>
      </Bottom>
      {/* <CheckIn onClick={handleCheckIn}>Check-in Attendee</CheckIn> */}
      <CheckIn onClick={handleCheckIn} disabled={ticket?.validated} validated={ticket?.validated}>
        {ticket?.validated ? "Checked-in" : "Check-in Attendee"}
      </CheckIn>

      <Cancel onClick={handleCancel}>Cancel</Cancel>
    </DetailsContainer>
  );
};

export default AttendeesTicketDetails;
