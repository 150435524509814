import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
// import { ISODateString } from "ui/types";

export type EditEventPayload = {
  id: number;
  data: {
    title?: string;
    banner?: File; // Updated to File
    organizerEmail?: string;
    category?: string;
    about?: string;
    type?: string;
    occurence?: string;
    timezone?: string;
    startDate?: string;
    endDate?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
  };
};

interface EditEventResponse {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string;
  timezone: string;
  startDate: string;
  endDate: string;
  location: string;
}

type UseEditEventResult = UseMutationResult<EditEventResponse, Error, EditEventPayload>;

export function useEditEventById(): UseEditEventResult {
  return useMutation({
    mutationFn: async (payload: EditEventPayload) => {
      const { id, data } = payload;
      const formData = new FormData();

      // Append data fields to FormData
      if (data.title) formData.append("title", data.title);
      if (data.banner) formData.append("banner", data.banner); // Append the File object
      if (data.organizerEmail) formData.append("organizerEmail", data.organizerEmail);
      if (data.category) formData.append("category", data.category);
      if (data.about) formData.append("about", data.about);
      if (data.type) formData.append("type", data.type);
      if (data.occurence) formData.append("occurence", data.occurence);
      if (data.timezone) formData.append("timezone", data.timezone);
      if (data.startDate) formData.append("startDate", data.startDate);
      if (data.endDate) formData.append("endDate", data.endDate);
      if (data.address) formData.append("address", data.address);
      if (data.city) formData.append("city", data.city);
      if (data.state) formData.append("state", data.state);
      if (data.country) formData.append("country", data.country);

      const res = await EvendyApi.patch<EditEventResponse>(`event/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return res.data;
    },
    mutationKey: [tqMutationKeys.event.edit],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("Event updated successfully", data);
    },
  });
}
