import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Upload,
  DatePicker,
  TimePicker,
  Checkbox,
  Switch,
  message,
  Row,
  Col,
} from "antd";
import { PlusOutlined, UploadOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useCreateTicket } from "lib/hooks/ticketing/useCreateEventTicketing";
import { ReactComponent as SelectImg } from "images/modal/Frameselect.svg";
import { ReactComponent as UploadLogo } from "images/organizerevents/upload.svg";
import { RcFile } from "antd/es/upload/interface";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { useEventContext } from "ui/components/event/eventContext";
import { format, parseISO } from "date-fns";
import { CreateTicketPayload } from "lib/hooks/ticketing/useCreateEventTicketing";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import { AppModal } from "ui/components/base";
import { TZDate } from "@date-fns/tz";

const { Option } = Select;

const Container = styled.div`
  padding: 20px;
  width: 100%;
`;

const Bottom = styled.div`
  margin-bottom: 1rem;
`;

const CategoryToggle = styled.div`
  display: flex;
  flex-direction: row;
  width: 550px;
  margin-bottom: 16px;
  gap: 1rem;
`;

const TicketCard = styled.div<{ selected?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
  height: 60px;
  border: 1px solid ${props => (props.selected ? "#1890ff" : "#d9d9d9")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const HiddenCheckbox = styled(Checkbox)`
  margin-top: 8px;
  .ant-checkbox-inner {
    border-color: rgba(28, 91, 135, 1);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(28, 91, 135, 1);
    border-color: rgba(28, 91, 135, 1);
  }
`;

const AdditionalSettings = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
  color: #1890ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateRow = styled(Row)`
  margin-bottom: 20px;
`;

const DateCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DateTimeContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
`;
const DateTimeFormItem = styled(Form.Item)`
  flex: 1;
  height: 50px;
`;
const Paragraph = styled.p`
  font-size: 0.5rem;
  line-height: 1rem;
`;
const MinMaxQuantityContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const TicketStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(249, 251, 255, 1);
  justify-content: space-between;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 50px;
`;
const StatusLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const StatusRight = styled.div``;

const StyledButton = styled(Button)`
  width: 100%;
  height: 46px;
  color: #fff;
  background-color: rgba(19, 144, 230, 1);
`;

const Right = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const EventTypeLabel = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: ${({ selected }) => (selected ? "#1C5B87" : "initial")};
`;

const EventTypeDescription = styled.p<{ selected?: boolean }>`
  margin-top: 0;
  margin-bottom: 0;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#888")};
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

const SmallerText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #595959;
`;

const Bluespan = styled.span`
  color: #1390e6;
`;

const beforeUpload = (file: RcFile): Promise<RcFile | boolean> => {
  const isSupportedFormat = ["image/svg+xml", "image/png", "image/jpeg", "image/gif"].includes(file.type);
  if (!isSupportedFormat) {
    message.error("You can only upload SVG, PNG, JPG, or GIF files!");
    return Promise.reject(false);
  }

  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const isValidDimensions = img.width <= 800 && img.height <= 400;
      if (!isValidDimensions) {
        message.error("Image dimensions should not exceed 800x400px!");
        reject(false);
      } else {
        resolve(file);
      }
    };
  });
};

type AddPackageModalProps = {
  closecreateticket: () => void;
  //to refresh
  onTicketAdded: () => void;
  eventId: number;
};

const CreateTicketModal: React.FC<AddPackageModalProps> = ({ closecreateticket, onTicketAdded, eventId }) => {
  const [showAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const timeformat = "HH:mm";

  const handleCardClick = (type: string) => {
    setSelectedType(prevType => (prevType === type ? null : type));
  };

  const [isCheckboxVisible, setIsCheckboxVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { mutate: createTicket, isError, error } = useCreateTicket();

  const handleAdditionalSettingsToggle = () => {
    setShowAdditionalSettings(prevState => !prevState);
  };

  const onFinish = (values: any) => {
    if (!eventId || eventId === 0) {
      message.error("create an event first");
      return; // Stop submission if eventId is invalid
    }
    setIsSubmitting(true);

    // Extract values from the form
    const { startDate, startTime, endDate, endTime } = values;
    console.log(startDate);
    console.log(startTime);

    // Combine the date and time strings
    const startDateTimeString = `${startDate.format("YYYY-MM-DD")}T${startTime.format("HH:mm")}:00`;
    const endDateTimeString = `${endDate.format("YYYY-MM-DD")}T${endTime.format("HH:mm")}:00`;

    // Create TZDate instances
    const startDateTime = new TZDate(startDateTimeString).toISOString();
    const endDateTime = new TZDate(endDateTimeString).toISOString();
    console.log(startDateTime);

    const payload: CreateTicketPayload = {
      category: selectedType ?? undefined,
      type: values.ticketName,
      description: values.ticketDescription,
      quantity: values.ticketQuantity,
      price: Number(values.ticketPrice) || 0, // Default to 0 if null
      image: values.banner?.file?.name || "string",
      salesStartDate: startDateTime,
      salesEndDate: endDateTime,
      minQuantity: values.minQuantityPerOrder || 1,
      maxQuantity: values.maxQuantityPerOrder || 10,
      eventId: Number(eventId), // assuming rawEventId is obtained from useEventContext
    };

    createTicket(payload, {
      onSuccess: () => {
        message.success("Ticket created successfully");
        form.resetFields();
        closecreateticket();
        setIsSubmitting(false);
        onTicketAdded();
      },
      onError: () => {
        setIsSubmitting(false);
      },
    });
  };

  // Open the modal
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  // Close the modal
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Form.Item name="ticketCategory" label="Ticket Category">
          <CategoryToggle>
            <TicketCard selected={selectedType === "free"} onClick={() => handleCardClick("free")}>
              <Left>
                <EventTypeLabel selected={selectedType === "free"}>Free ticket</EventTypeLabel>
                <EventTypeDescription selected={selectedType === "free"}>
                  Attendees don’t pay for Ticket
                </EventTypeDescription>
              </Left>
              <Right visible={selectedType === "free"}>
                <SelectImg />
              </Right>
            </TicketCard>

            <TicketCard selected={selectedType === "paid"} onClick={() => handleCardClick("paid")}>
              <Left>
                <EventTypeLabel selected={selectedType === "paid"}>Paid ticket</EventTypeLabel>
                <EventTypeDescription selected={selectedType === "paid"}>
                  Attendees pay for tickets
                </EventTypeDescription>
              </Left>
              <Right visible={selectedType === "paid"}>
                <SelectImg />
              </Right>
            </TicketCard>
          </CategoryToggle>
        </Form.Item>

        <Form.Item
          name="ticketName"
          label="Ticket Name/Type"
          rules={[{ required: true, message: "Please input the event name!" }]}
        >
          <Input placeholder="e.g. Regular, VIP etc" />
        </Form.Item>

        <Form.Item
          name="ticketDescription"
          label="Description (optional)"
          rules={[{ required: true, message: "Please input the ticket description!" }]}
        >
          <Input.TextArea rows={4} placeholder="Describe the purpose of this ticket briefly in just a few words." />
        </Form.Item>

        <Form.Item name="ticketQuantity" label="Ticket Quantity">
          <InputNumber min={1} defaultValue={0} style={{ width: "100%" }} size="large" />
        </Form.Item>

        {selectedType === "paid" && (
          <Form.Item name="ticketPrice" label="Ticket Price" rules={[{ required: true, message: "" }]}>
            <Input placeholder="" prefix="NGN" />
          </Form.Item>
        )}

        {selectedType === "free" && (
          <Form.Item name="ticketPrice" label="Ticket Price">
            <Input.Group compact>
              <Select defaultValue="NGN">
                <Option value="NGN">NGN</Option>
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
              </Select>
              <InputNumber
                value={form.getFieldValue("ticketPrice")}
                style={{ width: "84%", padding: "2px", marginLeft: "10px" }}
                placeholder="Free"
                disabled
                size="large"
              />
            </Input.Group>
          </Form.Item>
        )}

        <AdditionalSettings onClick={handleAdditionalSettingsToggle}>
          <span>Additional Settings</span>
          {showAdditionalSettings ? <UpOutlined /> : <DownOutlined />}
        </AdditionalSettings>

        {showAdditionalSettings && (
          <Bottom>
            <Form.Item label="Event Banner" name="banner" style={{ width: "100%" }}>
              <Upload.Dragger
                name="banner"
                listType="picture-card"
                accept=".svg,.png,.jpg,.jpeg,.gif"
                maxCount={1}
                beforeUpload={beforeUpload}
                style={{ backgroundColor: "white" }}
              >
                <UploadLogo />
                <SmallText>
                  <Bluespan>Click to upload</Bluespan> or drag and drop
                </SmallText>
                <SmallText>SVG, PNG, JPG or GIF (max. 800x400px)</SmallText>
              </Upload.Dragger>
            </Form.Item>

            <TicketStatus>
              <StatusLeft>
                <SmallText>Ticket Status: Available</SmallText>
                <SmallerText>will be displayed on the published event</SmallerText>
              </StatusLeft>
              <StatusRight>
                <Switch />
              </StatusRight>
            </TicketStatus>

            <DateRow gutter={16}>
              <DateCol span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[{ required: true, message: "Please select a start date" }]}
                >
                  <DatePicker style={{ width: "100%", height: "56px" }} />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[{ required: true, message: "Please select an end date" }]}
                >
                  <DatePicker style={{ width: "100%", height: "56px" }} />
                </Form.Item>
              </DateCol>

              <DateCol span={12}>
                <Form.Item
                  label="Start Time"
                  name="startTime"
                  rules={[{ required: true, message: "Please select a start time" }]}
                >
                  <TimePicker style={{ width: "100%", height: "56px" }} format={timeformat} />
                </Form.Item>

                <Form.Item
                  label="End Time"
                  name="endTime"
                  rules={[{ required: true, message: "Please select an end time" }]}
                >
                  <TimePicker style={{ width: "100%", height: "56px" }} format={timeformat} />
                </Form.Item>
              </DateCol>
            </DateRow>

            <MinMaxQuantityContainer>
              <DateTimeFormItem name="minQuantityPerOrder" label="Minimum Quantity Per Order">
                <InputNumber min={0} defaultValue={1} style={{ width: "100%" }} size="large" />
              </DateTimeFormItem>
              <DateTimeFormItem name="maxQuantityPerOrder" label="Maximum Quantity Per Order">
                <InputNumber min={0} defaultValue={10} style={{ width: "100%" }} size="large" />
              </DateTimeFormItem>
            </MinMaxQuantityContainer>
          </Bottom>
        )}

        <Form.Item>
          <SubmitButton form={form} loading={isUpdating}>
            Create Ticket
          </SubmitButton>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default CreateTicketModal;
