import React from "react";
import { TicketProvider } from "lib/context/ticketpurchase/ticketContext";
import TicketModal from "./TicketModal";

const WrappedTicketModal = () => {
  return (
    <TicketProvider>
      <TicketModal />
    </TicketProvider>
  );
};

export default WrappedTicketModal;
