import React, { createContext, useContext, useState } from "react";

// Define the types
interface Attendee {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

// interface Order {
//   eventTicketId: number;
//   count: number;
// }

interface Order {
  eventTicketId: number;
  count: number;
  ticketName: string;
  individualAmount: number;
}

interface TicketData {
  attendee?: Attendee;
  orders: Order[];
  totalAmount: number;
}

interface TicketContextProps {
  ticketData: TicketData;
  currentView: string | null;
  updateTicketData: (data: Partial<TicketData>, tickets?: any[]) => void;
  updateCurrentView: (view: string) => void;
}

// Default state
const defaultTicketData: TicketData = {
  orders: [],
  totalAmount: 0,
};

const TicketContext = createContext<TicketContextProps | undefined>(undefined);

// TicketProvider implementation
export const TicketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ticketData, setTicketData] = useState<TicketData>(defaultTicketData);
  const [currentView, setCurrentView] = useState<string | null>(null);

  const updateTicketData = (data: Partial<TicketData>, tickets?: any[]) => {
    setTicketData(prevData => {
      const updatedData = {
        ...prevData,
        ...data,
      };

      // Recalculate totalAmount if orders or tickets change
      if (data.orders && tickets) {
        updatedData.totalAmount = data.orders.reduce((total, order) => {
          const ticket = tickets.find((t: any) => t.id === order.eventTicketId);
          return ticket ? total + ticket.price * order.count : total;
        }, 0);
      }

      return updatedData;
    });
  };

  const updateCurrentView = (view: string) => {
    setCurrentView(view);
  };

  return (
    <TicketContext.Provider
      value={{
        ticketData,
        currentView,
        updateTicketData,
        updateCurrentView,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};

// Custom hook to use the TicketContext
export const useTicketContext = () => {
  const context = useContext(TicketContext);
  if (!context) {
    throw new Error("useTicketContext must be used within a TicketProvider");
  }
  return context;
};
