import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

export type InitiateTicketPayload = {
  eventId: number;
  attendee: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  orders: {
    eventTicketId: number;
    count: number;
  }[];
};

export interface InitiateTicketResponse {
  attendee: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
  order: {
    id: number;
    eventId: number;
    attendeeId: number;
    eventTicketId: number;
    sessionId: string;
    ticketCategory: string;
    ticketName: string;
    quantity: number;
    price: number;
    status: string;
    attendee: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
    event: {
      id: number;
      title: string;
      banner: string;
      organizerEmail: string;
      category: string;
      about: string;
      type: string;
      publishStatus: string;
      location: string;
      occurrence: string;
      timezone: string;
      startDate: string;
      endDate: string;
    };
    eventTicket: {
      id: number;
      category: string;
      type: string;
      description: string;
      quantity: number;
      price: number;
      image: string;
      salesStartDate: string;
      salesEndDate: string;
      minQuantity: number;
      maxQuantity: number;
      eventId: number;
    };
  }[];
  totalPrice: number;
  sessionId: string;
  reference: string;
  accessCode: string;
}

type UseInitiateTicketResult = UseMutationResult<InitiateTicketResponse, Error, InitiateTicketPayload>;

export function useInitiateTicket(): UseInitiateTicketResult {
  return useMutation({
    mutationFn: async (payload: InitiateTicketPayload) => {
      const res = await EvendyApi.post<InitiateTicketResponse>("ticket-purchase/init", payload);
      return res.data;
    },
    mutationKey: [tqMutationKeys.ticket.initiate],
    onError(error: Error) {
      console.error("Error initiating ticket:", error);
    },
    onSuccess(data) {
      console.log("Ticket initiated successfully:", data);
    },
  });
}
