import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

interface EventDetails {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  about: string;
  type: string;
  occurence: string;
  timezone: string;
  startDate: string;
  endDate: string;
  location: string;
  publishStatus: string;
  createdAt: string;
  updatedAt: string;
}

interface EventTicket {
  id: number;
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  createdAt: string;
  updatedAt: string;
  event: EventDetails;
  eventId: number;
}

type EventTicketResult = UseQueryResult<EventTicket[], Error>;

type GetEventTicketResultByEventIdPayload = {
  eventid: number;
};

export function useGetEventTicketsByEventId(payload: GetEventTicketResultByEventIdPayload): EventTicketResult {
  const { eventid } = payload;

  return useQuery({
    queryKey: [tqQueryKeys.ticket.getTicketByEventId, eventid],
    queryFn: async (): Promise<EventTicket[]> => {
      const res = await EvendyApi.get<EventTicket[]>(`ticket/find-by-event/${eventid}`);
      return res.data;
    },
    enabled: !!eventid,
    retry: false,
  });
}
