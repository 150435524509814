import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

interface Event {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  publishStatus: string;
  location: string;
  status: string;
  twitter: string;
  facebook: string;
  linkedin: string;
  instagram: string;
}

type EventResult = UseQueryResult<Event, Error>;

type GetEventPayload = {
  id: number;
};

export function useGetEventById(payload: GetEventPayload): EventResult {
  const { id } = payload;

  return useQuery({
    queryKey: [tqQueryKeys.event.getEventById, id],
    queryFn: async (): Promise<Event> => {
      const res = await EvendyApi.get<Event>(`event/${id}`);
      return res.data;
    },
    enabled: !!id,
    retry: false,
  });
}
