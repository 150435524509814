import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { EvendyApi } from "lib/utils/api-utils";
import { tqMutationKeys } from "lib/constants";

export type TicketPurchaseCompletePayload = {
  sessionId: string;
  //   transactionId: string;
};

export interface TicketPurchaseCompleteResponse {
  success: boolean;
  message: string;
}

type UseTicketPurchaseCompleteResult = UseMutationResult<
  TicketPurchaseCompleteResponse,
  Error,
  TicketPurchaseCompletePayload
>;

export function useTicketPurchaseComplete(): UseMutationResult<
  TicketPurchaseCompleteResponse,
  Error,
  TicketPurchaseCompletePayload
> {
  return useMutation({
    mutationFn: async (payload: TicketPurchaseCompletePayload) => {
      const res = await EvendyApi.post<TicketPurchaseCompleteResponse>("ticket-purchase/complete", payload);
      return res.data;
    },
    mutationKey: [tqMutationKeys.ticket.complete],

    retry: false,
    onError(error: Error) {
      console.error("Error completing ticket purchase:", error);
    },
    onSuccess(data) {
      console.log("Ticket purchase completed successfully:", data);
    },
  });
}
