import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { routeNames } from "lib/routes";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Tabs, Select, Input, message } from "antd";
import type { TabsProps } from "antd";
import Dashboard from "./Dashboard";
import Eventinformation from "./EventInformation/EventInformation";
import Vendors from "./Vendors";
import Attendees from "./Attendees";
import EventTickets from "./EventTickets";
import Preview from "./Preview";
import { AppModal } from "ui/components/base";
import { useGetEventById, useEventPublish } from "lib/hooks";
import WithdrawalModal from "./EventInformation/WithdrawalModal";

const VendorProfileWrapper = styled.div`
  width: calc(100% - 2rem);
  margin-top: -1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-top: 3rem;
    width: calc(100% - 2rem);
  }
`;

const BackTomarketPlace = styled.div`
  color: #1390e6;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TabsContainer = styled.div`
  .ant-tabs-nav {
    width: 60%; /* Adjust the width of the tabs header */
  }

  .ant-tabs-content-holder {
    width: 100%; /* Ensure content takes full width */
  }

  @media (max-width: 768px) {
    .ant-tabs-nav {
      width: 100%; /* On smaller screens, set header width to full */
    }
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const Bottom = styled.div``;

const Withdraw = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 12px 40px;
  font-size: 13px;
  width: 177px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Publish = styled.button<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? "#B0B0B0" : "#1390e6")};
  color: white;
  border: 1px solid;
  border-color: ${({ disabled }) => (disabled ? "#B0B0B0" : "#1390e6")};
  border-radius: 8px;
  padding: 12px 40px;
  font-size: 13px;
  width: 177px;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const onChange = (key: string) => {
  console.log(key);
};

const EventManagement: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [eventId, setEventId] = useState(0);
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { mutate: publishEvent } = useEventPublish();
  const [isPublished, setIsPublished] = useState(false);

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  // Close the modal
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setEventId(id);
  }, [params.id]);

  const { data: event, error: eventError } = useGetEventById({ id: eventId });

  useEffect(() => {
    if (event?.publishStatus === "published") {
      setIsPublished(true);
    } else {
      setIsPublished(false);
    }
  }, [event]);

  const handlePublish = () => {
    if (eventId) {
      publishEvent(
        { id: eventId, data: { publishStatus: "published" } },
        {
          onSuccess: () => {
            message.success("Event published successfully");
            setIsPublished(true);
          },
          onError: () => {
            console.log(eventError);
          },
        },
      );
    } else {
      console.error("Event ID is missing.");
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Dashboard",
      children: <Dashboard event={event} />,
    },
    {
      key: "2",
      label: "Event information",
      children: <Eventinformation event={event} />,
    },
    {
      key: "3",
      label: "Vendors",
      children: <Vendors event={event} />,
    },
    {
      key: "4",
      label: "Attendees",
      children: <Attendees event={event} />,
    },
    {
      key: "5",
      label: "Event Tickets",
      children: <EventTickets event={event} />,
    },
    {
      key: "6",
      label: "Preview",
      children: <Preview event={event} />,
    },
  ];

  return (
    <VendorProfileWrapper>
      <Top>
        {" "}
        <StyledLink to={routeNames.organizerEvents}>
          <BackTomarketPlace>
            <LeftOutlined /> Back to Event
          </BackTomarketPlace>
        </StyledLink>
        <ButtonContainer>
          <Publish onClick={handlePublish} disabled={isPublished}>
            Publish event
          </Publish>
          <Withdraw onClick={handleModalOpen}>Withdraw</Withdraw>
        </ButtonContainer>
      </Top>
      <Bottom>
        <TabsContainer>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </TabsContainer>
      </Bottom>

      {/* Modal for withdrawal */}
      <AppModal open={isModalVisible} header="Withdraw" onCancel={handleModalClose} size="xxl">
        <WithdrawalModal />
      </AppModal>
    </VendorProfileWrapper>
  );
};

export default EventManagement;
