import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { Button } from "antd";
import { useGetEventTicketsByEventId } from "lib/hooks/ticketing";

const CardWrapper = styled.div`
  width: calc(23.8% - 15px);
  /* padding: 6px; */
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    width: calc(31.8% - 15px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const EventImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 10px;
`;

const EventTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-left: 1rem;
`;

const EventDate = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #999;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  margin-left: 1rem;
`;

const Middle = styled.div`
  gap: 0.3rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
`;

const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const SmallText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #595959;
  margin: 0;
`;

const SmallBoldText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  margin: 0;
  color: #121212;
`;

const MediumText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #121212;
  margin: 0;
`;

const EventCategory = styled.button`
  background-color: #ffffff;
  color: #026aa2;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 15px;
  padding: 5px 5px;
  font-size: 10px;
`;

const GetTicket = styled(Button)`
  display: flex;
  align-items: center;
  border-color: #1890ff;
  color: #1890ff;
  height: 46px;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 10px;
`;

const Line = styled.div`
  width: 100%;
  opacity: 50%;
  border: 0.5px solid rgba(226, 232, 240, 1);
`;

interface Event {
  id: number;
  title: string;
  banner: string;
  category: string;
  startDate: string;
  endDate: string;
  location: string;
  type: string;
}

interface CardProps {
  event: Event;
}

const Card: React.FC<CardProps> = ({ event }) => {
  const Onclick = () => {};
  const formattedDate = new Date(event.startDate).toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = new Date(event.startDate).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const [eventId, setEventId] = useState<number>(0);
  const [locationParts, setLocationParts] = useState<{ address: string; city: string; state: string; country: string }>(
    {
      address: "",
      city: "",
      state: "",
      country: "",
    },
  );

  useEffect(() => {
    if (event.id) setEventId(event.id);
  }, [event.id]);

  useEffect(() => {
    if (event && event.location) {
      const [address, city, state, country] = event.location.split("___");
      setLocationParts({ address, city, state, country });
    }
  }, [event]);

  const { data: tickets, isLoading, isError } = useGetEventTicketsByEventId({ eventid: eventId });
  const lowestPrice = tickets && tickets.length > 0 ? Math.min(...tickets.map(ticket => ticket.price)) : null;

  return (
    <CardWrapper>
      <StyledLink to={`${routeNames.eventDetail}/${event.id}`}>
        <ImageContainer>
          <EventImage src="/Images/Events/cardimg.png" alt={event.title} />
        </ImageContainer>
        <Top>
          <EventCategory>{event.category}</EventCategory>
          <MediumText>{event.title}</MediumText>
        </Top>
        <Middle>
          <SmallText>{`${formattedDate} || ${formattedTime}`}</SmallText>
          <SmallText>{`${locationParts.address}, ${locationParts.city}, ${locationParts.state}, ${locationParts.country}`}</SmallText>
          <SmallText>{event.type}</SmallText>
        </Middle>
        <Line />
        <Bottom>
          <BottomLeft>
            <SmallText>From</SmallText>
            <SmallBoldText>₦ {lowestPrice !== null ? lowestPrice.toLocaleString() : "N/A"}</SmallBoldText>
          </BottomLeft>
          <GetTicket onClick={Onclick}>Get tickets</GetTicket>
        </Bottom>
      </StyledLink>
    </CardWrapper>
  );
};

export default Card;
