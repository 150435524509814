import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Select, Button } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  margin-top: 5rem;
  gap: 1.5rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: #595959;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

const StyledButton = styled(Button)`
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 12px 40px;
  font-size: 13px;
  height: 46px;
  padding: 0.5rem 1rem;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  cursor: pointer;
  &:disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: not-allowed;
  }
`;

const WithdrawalModal = () => {
  const [price, setPrice] = useState("");

  const options = [
    { value: "NGN", label: "NGN" },
    { value: "USD", label: "USD" },
  ];

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrice(e.target.value);
  };

  return (
    <Container>
      <Top>
        <SmallText>How much will you like to withdraw?</SmallText>
      </Top>
      <Price>
        <Form.Item name="currency" rules={[{ required: true, message: "Please select a currency!" }]}>
          <Select placeholder="NGN" options={options} style={{ width: "100%", background: "#F6F6F6" }} />
        </Form.Item>
        <Form.Item name="unitprice" rules={[{ required: true, message: "" }]}>
          <Input placeholder="" style={{ width: "300px" }} onChange={handlePriceChange} />
        </Form.Item>
      </Price>
      <Bottom>
        <StyledButton type="primary" disabled={!price}>
          Submit
        </StyledButton>
      </Bottom>
    </Container>
  );
};

export default WithdrawalModal;
