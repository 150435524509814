import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { EvendyApi } from "lib/utils/api-utils";
import { tqQueryKeys } from "lib/constants";

interface Attendee {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt: string;
}

interface TicketResponse {
  sessionId: string;
  eventTicketId: number;
  eventName: string;
  ticketType: string;
  ticketCode: string;
  qrCode: string;
  attendee: Attendee;
  validated: boolean;
}

type TicketResult = UseQueryResult<TicketResponse, Error>;

export type GetTicketPayload = {
  ticketCode: string;
  sessionId: string;
  firstName?: string;
};

export function useGetTicketByCode(payload: GetTicketPayload): TicketResult {
  const { ticketCode, sessionId, firstName } = payload;

  const params = {
    ...(ticketCode && { ticketCode }),
    ...(sessionId && { sessionId }),
    ...(firstName && { firstName }),
  };

  return useQuery({
    queryKey: [tqQueryKeys.ticket.getByCode, ticketCode, sessionId, firstName, params],
    queryFn: async (): Promise<TicketResponse> => {
      const res = await EvendyApi.get<TicketResponse>("ticket-purchase/find-by-ticket-code", { params });
      console.log("API Response:", res.data);
      return res.data;
    },
    enabled: !!ticketCode,
    retry: false,
  });
}
